// App.js
import React, { useState, useEffect } from "react";
import "./styles.css";
import Monster from './Monster';
import Friends from './Friends';
import Earn from './Earn';

function App() {
  const [user, setUser] = useState({ avatar: "", name: "" });
  const [monBalance, setMonBalance] = useState(null);
  const [tonBalance, setTonBalance] = useState(null);
  const [minerList, setMinerList] = useState([]);
  const [minerLastClaimTimes, setMinerLastClaimTimes] = useState({});
  const [currentView, setCurrentView] = useState('home'); // 新增状态来管理当前视图

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();

      // if (!window.Telegram.WebApp.isFullscreen) {
      //   window.Telegram.WebApp.requestFullscreen();
      // }
    }

    window.Telegram.WebApp.BackButton.onClick(() => {
      window.Telegram.WebApp.close();
    });

    const initData = window.Telegram.WebApp.initDataUnsafe;
    if (initData && initData.user) {
      const { first_name, last_name, username, photo_url, id } = initData.user;
      setUser({
        name: `${first_name || ""}`,
        avatar: photo_url || "https://via.placeholder.com/50",
      });

      if (id) {
        fetchUserData(id);
      }
    }

    function isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    const logoElement = document.querySelector('.logo');
    if (logoElement) {
      if (isIOS()) {
        logoElement.style.paddingTop = '30px';
      } else {
        logoElement.style.paddingTop = '10px';
      }
    }
  }, []);

  const fetchUserData = async (telegramId) => {
    try {
      const response = await fetch(`https://api.tonchain.monster/api/login?telegram_id=${telegramId}`);
      const data = await response.json();
      if (data && data.code === 1 && data.data) {
        setMonBalance(data.data.mon_balance);
        setTonBalance(data.data.ton_balance);
        setUser({
          name: data.data.first_name,
          avatar: data.data.avatar,
        });
        setMinerList(data.data.minerList || []);

        // 存储每个矿工的 last_claim_time，并转换为毫秒
        const lastClaimTimes = {};
        data.data.minerList.forEach((miner, index) => {
          lastClaimTimes[index] = new Date(miner.last_claim_time).getTime(); // 转换为毫秒
        });
        setMinerLastClaimTimes(lastClaimTimes);
      } else {
        setMonBalance(0);
        setTonBalance(0);
        setMinerList([]);
        setMinerLastClaimTimes({});
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setMonBalance(0);
      setTonBalance(0);
      setMinerList([]);
      setMinerLastClaimTimes({});
    }
  };

  const handleResetMiners = () => {
    console.log("Resetting miners");
    setMinerList([]);
  };

  const calculatePerHourEarnings = (miner) => {
    let totalAtHp = 0;
    for (let i = 1; i <= 3; i++) {
      const atKey = `card_${i}_at`;
      const hpKey = `card_${i}_hp`;
      totalAtHp += (miner.card[atKey] || 0) + (miner.card[hpKey] || 0);
    }
    return (totalAtHp * 0.01).toFixed(4);
  };

  useEffect(() => {
    console.log("Initial minerList:", minerList);
    console.log("Initial minerLastClaimTimes:", minerLastClaimTimes);

    const interval = setInterval(() => {
      setMinerList(prevMiners => prevMiners.map((miner, index) => {
        const lastClaimTime = minerLastClaimTimes[index];
        if (lastClaimTime === undefined) {
          console.warn(`lastClaimTime for miner ${index} is undefined`);
          return miner;
        }
        const timePassed = (Date.now() - lastClaimTime) / 3600000; // 转换为小时
        const earningsPerHour = calculatePerHourEarnings(miner);
        const toClaim = timePassed * earningsPerHour;

        // 确保 toClaim 不超过 max_storage
        const cappedToClaim = Math.min(toClaim, miner.max_storage);

        return {
          ...miner,
          toClaim: cappedToClaim.toFixed(4)
        };
      }));
    }, 1000); // 每秒更新一次

    return () => clearInterval(interval); // 清除定时器
  }, [minerList, minerLastClaimTimes]);

  return (

    <div className="app">
      {currentView === 'home' && (
        <>
          <div className="logo">
            <img src="https://www.tonchain.monster/image/logo.png" />
          </div>
          <header className="header">
            <div className="user-info">
              <img src={user.avatar} className="avatar" />
              <div className="user-bar">
                <span className="user-name">{user.name || "Guest"}</span>
                <img className="walletButton" src="https://www.tonchain.monster/image/wallet.png" />
              </div>
            </div>
            <div className="balance-info">
              <div className="monBalance">
                <span>{monBalance !== null ? monBalance : 'Loading...'}</span>
              </div>
              <img className="monIcon" src="https://www.tonchain.monster/image/moncoin.png" />
              <div className="tonBalance">
                <span>{tonBalance !== null ? tonBalance : 'Loading...'}</span>
              </div>
              <img className="tonIcon" src="https://www.tonchain.monster/image/toncoin.png" />
            </div>
          </header>
          <div className="swap">
            <img src="https://www.tonchain.monster/image/swap.png" />
          </div>
          {minerList.map((miner, index) => (
            <div className="mTeam" key={index}>
              <div className="mTitle">
                <div className="teamTitle">Mining Team #{index + 1}</div>
                <img src="https://www.tonchain.monster/image/change.png" onClick={handleResetMiners} style={{ cursor: 'pointer' }} />
              </div>
              <div className="mBox">
                <div className="numCard">
                  <div className="mNum">
                    <div className="toClaim">
                      <img src="https://www.tonchain.monster/image/moncoin.png" />
                      <span>{miner.toClaim || '0.0000'}</span>
                    </div>
                    <div className="perHr">{calculatePerHourEarnings(miner)} $MON / hour</div>
                  </div>
                  <div className="mCards">
                    {[...Array(3)].map((_, cardIndex) => (
                      <img key={cardIndex} src={miner.card[`card_${cardIndex + 1}_pic`] || "https://www.tonchain.monster/card/hawke.png"} />
                    ))}
                  </div>
                </div>

                <div className="claim">
                  <div className="progress">
                    <div className="maxCoin"><div>max {miner.max_storage.toFixed(4)} $MON</div></div>
                    <div className="bar">
                      <div
                        className="barProgress"
                        style={{
                          width: `${((miner.toClaim / miner.max_storage) * 100).toFixed(2)}%`
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="claimBtn">
                    <div className="btClaim">Claim</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="teamPlus"><div className="plusBox">+</div></div>
        </>
      )}

      {currentView === 'monster' && (
        <Monster />
      )}

      {currentView === 'friends' && (
        <Friends />
      )}

      {currentView === 'earn' && (
        <Earn />
      )}

      <footer className="footer">
        <div className="footerBTN">
          <div className="footerMine" onClick={() => setCurrentView('home')}><img src="https://www.tonchain.monster/image/bt_mine.png" /></div>
          <div className="footerMonster" onClick={() => setCurrentView('monster')}>
            <img src="https://www.tonchain.monster/image/bt_monster.png" />
          </div>
          <div className="footerArena" onClick={() => setCurrentView('monster')}><img src="https://www.tonchain.monster/image/bt_arena.png" /></div>
          <div className="footerFriends" onClick={() => setCurrentView('friends')}><img src="https://www.tonchain.monster/image/bt_friends.png" /></div>
          <div className="footerEarn" onClick={() => setCurrentView('earn')}><img src="https://www.tonchain.monster/image/bt_earn.png" /></div>
        </div>
      </footer>
    </div>
  );
}

export default App;