import React, { useState, useRef, useEffect } from 'react';
import './styles.css'; // 确保引入了样式文件

const Monster = () => {
  const [cardPic, setCardPic] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const cardRef = useRef(null); // 创建一个ref来引用卡牌元素

  const drawCard = async () => {
    setIsAnimating(true); // 开始动画
    if (cardRef.current) {
      cardRef.current.style.setProperty('--shake-count', '0'); // 初始化抖动计数
      cardRef.current.classList.add('shake-random'); // 添加抖动动画类
      let shakeCount = 0;
      let intervalTime = 100; // 初始时间间隔

      const directions = ['left', 'right', 'up', 'down'];
      const interval = setInterval(() => {
        shakeCount++;
        cardRef.current.style.setProperty('--shake-count', shakeCount.toString());

        // 随机选择一个方向
        const direction = directions[Math.floor(Math.random() * directions.length)];
        cardRef.current.style.setProperty('--shake-direction', direction);

        // 调整时间间隔
        intervalTime -= 2; // 每次减少2毫秒
        if (intervalTime < 10) {
          intervalTime = 10; // 最小时间间隔为10毫秒
        }

        if (shakeCount >= 20) {
          clearInterval(interval);
        }
      }, intervalTime); // 初始时间间隔为100毫秒
    }

    setTimeout(async () => {
      try {
        const response = await fetch('https://api.tonchain.monster/api/carddraw?telegram_id=75645342&card_pack_id=4');
        const data = await response.json();
        if (data.code === 1 && data.data) {
          setCardPic(data.data.cardPic);
        } else {
          console.error('Failed to draw card:', data.message);
        }
      } catch (error) {
        console.error('Error drawing card:', error);
      } finally {
        setIsAnimating(false); // 结束动画
        if (cardRef.current) {
          cardRef.current.classList.remove('shake-random'); // 移除抖动动画类
        }
      }
    }, 1000); // 动画持续时间为1秒
  };

  return (
    <div className="drawCardBody">
      {isAnimating && <div className="fullscreenOverlay brighten"></div>}
      <div className='drawCardBox'>
        {cardPic && <img ref={cardRef} className='drawCardResult' src={cardPic} alt="Drawn Card" />}
      </div>
      <div className='drawCardBtn'>
        <button onClick={drawCard}>Draw Card</button>
      </div>
    </div>
  );
};

export default Monster;