// Friends.js
import React from 'react';

const Friends = () => {
  return (
    <div>
      <h1 style={{ color: 'white' }}>MY FRIEND</h1>
    </div>
  );
};

export default Friends;