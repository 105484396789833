// Earn.js
import React from 'react';

const Earn = () => {
  return (
    <div>
      <h1 style={{ color: 'white' }}>Tasks</h1>
    </div>
  );
};

export default Earn;